// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("bootstrap")
// require("bootstrap-autocomplete")

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = require("jquery")

// For cocoon
require("jquery")
require("@nathanvda/cocoon")
require("jquery-ui")



import "./patient_prescription";
import "./prescription_guideline";

import "../stylesheets/application";

import send_patient_explanation from './prescription_guideline'
window.send_patient_explanation = send_patient_explanation


// document.addEventListener("turbolinks:load", function () {

    $(document).ready(function () {
        $('.datepicker').datepicker({
            dateFormat: 'dd/mm/yy',
            changeYear: true,
            changeMonth: true,
            yearRange: (new Date().getFullYear() - 100) + ":" + (new Date().getFullYear()),
        });
    });

// })


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
