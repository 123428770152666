function send_patient_explanation(patient_email, guidelines_id) {
    if (patient_email === "") {
        $('#errorModalContent').text('Please enter patient email on patient details page.');
        $('#errorModal').modal('show');
    } else if (!guidelines_id) {
        $('#errorModalContent').text('You are doing something you are not supposed to!');
        $('#errorModal').modal('show');
    } else {
        $.ajax({
            url: "/prescription_guidelines/" + guidelines_id + "/send_email_to_patient",
            // url: "/prescription_guidelines/999/send_email_to_patient",
            method: 'put',
            dataType: 'text',
            data: {
                email: patient_email,
            },
            success: function (data, textStatus, jqXHR) {
                $('#successModalContent').text(data);
                $('#successModal').modal('show');

            },
            error: function (xhr, ajaxOptions, thrownError) {
                $('#errorModalContent').text(xhr.responseText);
                $('#errorModal').modal('show');
            }
        });
    }
}

export default send_patient_explanation;

// document.addEventListener("turbolinks:load", () => {
    $(document).ready(function () {


        (function ($) {

            $(".send-patient-explanation-button").on("click", function () {

                const patient_email = $('#patient_email').val();
                const guidelines_id = $(this).val();


                ;
            });
        })(jQuery);
    })
// })
