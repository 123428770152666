// document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {
    $('div.patient_prescription_patient_category').hide();
    $('div.patient_prescription_route_of_admin').hide();

    $('#patient-prescription-card').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
        // TODO assign new collapse ID for newly added dose calculation fields
        //Retrieve selected values
        let prescription_name = $('#prescription_name').val();
        let prescription_patient_category = $('#prescription_patient_category').val();
        let prescription_route_of_admin = $('#prescription_route_of_admin').val();

        if (window.dataLayer) {
            window.dataLayer.push({
                'event': 'lookup_patient_prescription',
                'prescription_name': prescription_name,
                'prescription_patient_category': prescription_patient_category,
                'prescription_route_of_admin': prescription_route_of_admin
            });
        }

        // Reset selected values
        $('#prescription_name').val('');
        $('#prescription_patient_category').val('');
        $('#prescription_route_of_admin').val('');

        retrieve_patient_prescriptions_description(prescription_name,
            prescription_patient_category,
            prescription_route_of_admin,
            function (results) {
                if (results.length == 1) {
                    // If a prescription drug matched

                    // Populate prescription ID and description
                    insertedItem.find('.prescription-id-input').val(results[0].id);
                    insertedItem.find('.prescription-description-input').val(results[0].prescription_description);
                    insertedItem.find('.check-drug-interaction-btn').attr("disabled", false);
                    insertedItem.find('.check-allergy-btn').attr("disabled", false);

                    // insertedItem.find('.dynamic-pregnancy-info').append(results[0].pregnancy_info);
                    // insertedItem.find('.dynamic-lactation-info').append(results[0].lactation_info);

                    let precaution = results[0].precaution;

                    if (precaution !== null) {
                        insertedItem.find('button.check-precaution-btn').prop('disabled', false);
                    }

                    insertedItem.find('button.check-precaution-btn').attr('data-content', precaution);
                    insertedItem.find('button.check-pregnancy-btn').attr('data-content', results[0].pregnancy_info);
                    insertedItem.find('button.check-lactation-btn').attr('data-content', results[0].lactation_info);
                    insertedItem.find('button.check-pregnancy-btn').attr("disabled", false);
                    insertedItem.find('button.check-lactation-btn').attr("disabled", false);

                    if (results[0].calculation) {
                        insertedItem.find('.dynamic-prescription-calculation-input').html(decode(results[0].calculation));
                    }

                    if (results[0].calculation_reference) {
                        insertedItem.find('.dynamic-prescription-calculation_reference-input').html(decode(results[0].calculation_reference));
                    }

                    let prescription_calculations = results[0].prescription_calculations;
                    let calculation_div = insertedItem.find('#dynamic-prescription-calculations')
                    if (prescription_calculations) {
                        let random_key = getRandomInt(1000);
                        prescription_calculations.forEach(function (calculation, index) {
                            let calculation_collapse_button = $('<a/>', {
                                className: 'card-title',
                                role: 'button',
                                'data-toggle': 'collapse',
                                'aria-expanded': 'false',
                                'aria-controls': '#collapse-' + random_key + '-calculation-' + calculation.id,
                                href: '#collapse-' + random_key + '-calculation-' + calculation.id,
                                html: calculation.title
                            });

                            let calculation_collapse_content = $('<div/>', {
                                id: 'collapse-' + random_key + '-calculation-' + calculation.id,
                                class: 'collapse',
                                html: decode(calculation.calculation)
                            })

                            $(calculation_div).append(calculation_collapse_button);
                            $(calculation_div).append(calculation_collapse_content);
                            $(calculation_div).append('<br>');


                        });


                    }

                }
                // Populate prescription name, category, and route of admin
                insertedItem.find('.dynamic-prescription-name-input').val(prescription_name);
                insertedItem.find('.dynamic-prescription-name').text(prescription_name);
                insertedItem.find('.prescription-patient-category-input').val(prescription_patient_category);
                insertedItem.find('.prescription-route-of-admin-input').val(prescription_route_of_admin);
            }
        )
    });


    function retrieve_patient_prescriptions_description(prescription_name,
                                                        patient_category,
                                                        route_of_admin,
                                                        onSuccess) {
        $.ajax({
            url: "/prescriptions_descriptions",
            dataType: 'json',
            data: {
                name: prescription_name,
                patient_category: patient_category,
                route_of_admin: route_of_admin,
            },
            success: onSuccess
        });
    }

    // On change event when user search for prescription name
    $('#prescription_name').change(function () {
        let prescription_name = $(this).val();

        $('div.patient_prescription_patient_category').hide();
        $('div.patient_prescription_route_of_admin').hide();
        $('#prescription_patient_category').empty();
        $('#prescription_route_of_admin').empty();

        retrieve_patient_category(prescription_name, null, function (results) {
            $('#prescription_patient_category').append($("<option />").val('').text(''))
            for (const result of results) {
                $('#prescription_patient_category').append($("<option />").val(result).text(result))
            }
            $('div.patient_prescription_patient_category').show();
        })

        retrieve_route_of_admin(prescription_name, null, function (results) {
            $('#prescription_route_of_admin').append($("<option />").val('').text(''))
            for (const result of results) {
                $('#prescription_route_of_admin').append($("<option />").val(result).text(result))
            }
            $('div.patient_prescription_route_of_admin').show();
        });

    });

    $('#prescription_patient_category').change(function () {
        let patient_category = $(this).val();
        let prescription_name = $('#prescription_name').val();

        retrieve_route_of_admin(prescription_name, patient_category, function (results) {
            let selected_route_of_admin = $('#prescription_route_of_admin').val();
            if (selected_route_of_admin === '') {
                $('#prescription_route_of_admin').empty();
                $('#prescription_route_of_admin').append($("<option />").val('').text(''))
                for (const result of results) {
                    $('#prescription_route_of_admin').append($("<option />").val(result).text(result))
                }
                $('div.patient_prescription_route_of_admin').show();
            }
        });
    });

    $('#prescription_route_of_admin').change(function () {
        let route_of_admin = $(this).val();
        let prescription_name = $('#prescription_name').val();

        retrieve_patient_category(prescription_name, route_of_admin, function (results) {
            let selected_patient_category = $('#prescription_patient_category').val();
            if (selected_patient_category === '') {
                $('#prescription_patient_category').empty();
                $('#prescription_patient_category').append($("<option />").val('').text(''))
                for (const result of results) {
                    $('#prescription_patient_category').append($("<option />").val(result).text(result))
                }
                $('div.patient_prescription_patient_category').show();
            }
        });
    });


    function retrieve_patient_category(prescription_name, route_of_admin, onSuccess) {
        $.ajax({
            url: "/prescriptions_patient_categories",
            dataType: 'json',
            data: {
                name: prescription_name,
                route_of_admin: route_of_admin,
            },
            success: onSuccess
        });
    }

    function retrieve_route_of_admin(prescription_name, patient_category, onSuccess) {
        $.ajax({
            url: "/prescriptions_route_of_admins",
            dataType: 'json',
            data: {
                name: prescription_name,
                patient_category: patient_category,
            },
            success: onSuccess
        });
    }

    $('#prescription').on('click', 'button.check-drug-interaction-btn', function () {
        let nested_field = $(this).parents('div.patient-prescription-nested-fields');
        let prescription_id = nested_field.find('input.patient-prescription-id').val();
        let patient_id = $('#patient_id').val();

        // Refresh drug interaction modal

        $('#drug-interaction-modal-not-found-content').hide();
        $('#drug-interaction-modal-error-content').hide();
        $('#drug-interaction-modal-found-content').hide();
        $('#drug-interaction-modal-found-content').empty()

        let interaction_found = false;

        retrieve_drug_interaction(prescription_id,
            patient_id,
            function (results, status) {
                //Retrieve interaction from response JSON object
                const interactions = results.interactions;
                if ((typeof interactions) !== "undefined" &&
                    interactions.length > 0) {
                    for (const interaction of interactions) {
                        process_drug_interaction(interaction);
                    }
                    interaction_found = true;
                    $('#drug-interaction-modal-not-found-content').hide();
                } else {
                    if (interaction_found !== true) {
                        $('#drug-interaction-modal-not-found-content').show();
                    }
                }
            },
            function (xhr, status, error) {
                $('#drug-interaction-modal-error-content').show();
            });
        $('#drugInteractionModal').modal('show');
    });

    function process_drug_interaction(interaction) {
        const interaction_pair = interaction.interactionPairs;
        const severity = interaction.severity;
        const documentation = interaction.documentation;
        const observation = interaction.observation;
        const precaution = interaction.precaution;
        const description = interaction.description;

        let interaction_div = $('<div></div>');
        let interaction_row = $('<dl class="row"></dl>');
        let severity_row = $('<dd class="title">Severity: </dd>' +
            '<dd class="description">' +
            '<span class="severity-' +
            severity.toLowerCase() + '">' +
            severity + '</span></dd>');

        let documentation_row = $('<dd class="title">Documentation: </dd>' +
            '<dd class="description">' +
            '<span class="documentation">' +
            documentation + '</span></dd>');

        let observation_row = $('<dd class="title">Observation: </dd>' +
            '<dd class="description">' +
            '<span class="observation">' +
            observation + '</span></dd>');

        let precaution_row = $('<dd class="title">Precaution: </dd>' +
            '<dd class="description">' +
            '<span class="precaution">' +
            precaution + '</span></dd>');

        let descriptionn_row = $('<dd class="title">Description: </dd>' +
            '<dd class="description">' +
            '<span class="description">' +
            description + '</span></dd>');

        $(interaction_row).append(severity_row);
        $(interaction_row).append(documentation_row);
        $(interaction_row).append(observation_row);
        $(interaction_row).append(precaution_row);
        $(interaction_row).append(descriptionn_row);

        $(interaction_div).append(interaction_row);

        $('#drug-interaction-modal-found-content').append(interaction_div);


        $('#drug-interaction-modal-found-content').show();
    }

    function retrieve_drug_interaction(prescription_id, patient_id, onSuccess, onError) {
        $.ajax({
            url: "/drug_interactions",
            dataType: 'json',
            data: {
                patient_id: patient_id,
                prescription_id: prescription_id,
            },
            beforeSend: function () {
                $('#drug-interaction-modal-loading-animation').show();
            },
            success: onSuccess,
            error: onError,
            complete: function () {
                $('#drug-interaction-modal-loading-animation').hide();
            }
        });
    }

    $('#prescription').on('click', 'button.check-allergy-btn', function () {
        let nested_field = $(this).parents('div.patient-prescription-nested-fields');
        let prescription_id = nested_field.find('input.patient-prescription-id').val();

        let patient_id = $('#patient_id').val();

        // Refresh drug allergy modal

        $('#drug-allergy-modal-not-found-content').hide();
        $('#drug-allergy-modal-error-content').hide();
        $('#drug-allergy-modal-found-content').hide();
        $('#drug-allergy-modal-found-content').empty()


        retrieve_drug_allergy(patient_id,
            prescription_id,
            function (results) {
                //Retrieve allergy from response JSON object
                if (results == null) {
                    $('#drug-allergy-modal-not-found-content').show();
                } else {
                    const allergies = results.allergies;
                    if ((typeof allergies) !== "undefined" &&
                        allergies.length > 0) {
                        let count = 0;
                        for (const allergy of allergies) {
                            process_drug_allergy(allergy, count);
                            count++;
                        }
                    } else {
                        $('#drug-allergy-modal-not-found-content').show();
                    }
                }

            },
            function (xhr, status, error) {
                $('#drug-allergy-modal-error-content').show();
            });

        $('#drugAllergyModal').modal('show');
    });

    function retrieve_drug_allergy(patient_id,
                                   prescription_id,
                                   onSuccess,
                                   onError) {

        $.ajax({
            url: "/drug_allergies",
            dataType: 'json',
            data: {
                patient_id: patient_id,
                prescription_id: prescription_id,
            },
            beforeSend: function () {
                $('#drug-allergy-modal-loading-animation').show();
            },
            success: onSuccess,
            error: onError,
            complete: function () {
                $('#drug-allergy-modal-loading-animation').hide();
            }
        });
    }

    function process_drug_allergy(allergy, count) {
        const allergy_type = allergy.allergyType;
        const summary_statement = allergy.summaryStatement;
        const allergen = allergy.allergen.name;
        const input = allergy.input.moleculeName;

        let allergy_div = $('<div></div>');
        let allergy_row = $('<dl class="row"></dl>');

        let allergy_type_row = $('<dd class="title">Allergy Type: </dd>' +
            '<dd class="description">' +
            '<span class="allergy_type">' +
            allergy_type + '</span></dd>');

        let summary_statement_row = $('<dd class="title">Summary: </dd>' +
            '<dd class="description">' +
            '<span class="observation">' +
            summary_statement + '</span></dd>');

        let allergen_row = $('<dd class="title">Allergen: </dd>' +
            '<dd class="description">' +
            '<span class="precaution">' +
            allergen + '</span></dd>');

        let input_row = $('<dd class="title">Prescription: </dd>' +
            '<dd class="description">' +
            '<span class="description">' +
            input + '</span></dd>');

        $(allergy_row).append(allergy_type_row);
        $(allergy_row).append(summary_statement_row);
        $(allergy_row).append(allergen_row);
        $(allergy_row).append(input_row);

        $(allergy_div).append(allergy_row);

        if (count > 0) {
            $('#drug-allergy-modal-found-content').append('<hr/>');
        }

        $('#drug-allergy-modal-found-content').append(allergy_div);


        // $('#drug-allergy-modal-not-found-content').hide();
        $('#drug-allergy-modal-found-content').show();
    }

    $('#prescription').on('click', 'button.check-pregnancy-btn', function () {
        let content = $(this).data('content');
        if (typeof content === 'undefined') {
            content = '';
        }
        $('#pregnancyModal').find('p.content-text').text(content);
        $('#pregnancyModal').modal('show');
    });

    $('#prescription').on('click', 'button.check-lactation-btn', function () {
        let content = $(this).data('content');
        if (typeof content === 'undefined') {
            content = '';
        }
        $('#lactationModal').find('p.content-text').text(content);
        $('#lactationModal').modal('show');
    });

    $('#prescription').on('click', 'button.check-precaution-btn', function () {
        let content = $(this).data('content');
        if (typeof content === 'undefined') {
            content = '';
        }
        $('#precautionModal').find('p.content-text').html(decode(content));
        $('#precautionModal').modal('show');
    });

    // Replace linebreak with <br> for Dose calculation
    function decode(str) {
        if (str && typeof str === 'string') {
            str = str.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }
        return str;
    }

    // Generate a random integer within the given range
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
})
// })